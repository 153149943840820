<template>
  <div>
    <div class="mx-auto pt-2" style="width: 50%;">
      <b-alert class="m-2 text-center" show v-if="Boolean(settings.lastUpdate)">Letztes Update: {{settings.lastUpdate}} Uhr</b-alert>
    </div>
    <div class="stickySearch">
      <div class="container">
        <div class="row">
          <div class="col-md-12 pb-3 pt-3" >
            <b-input-group size="md">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="suchen..."
              ></b-form-input>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="data !== undefined && data !== ''">
        <b-table :fields="fields" :items="filterData" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" class="my-sticky-header"  striped>

          <template #cell(comlineSku)="cellData">
            <span><b>{{cellData.value }}</b></span>
          </template>

          <template #cell(manufacturerSku)="cellData">
            {{cellData.value}}
          </template>

          <template #cell(ordernumbers)="cellData">
            <b >{{cellData.value.altOrNo}}</b><br>({{cellData.value.orNo}})
          </template>

          <template #cell(trackingNo)="cellData">
            <a :href="'https://www.ups.com/track?loc=de_DE&tracknum='+cellData.value" target="_blank">{{cellData.value}}</a>
          </template>

        </b-table>
      </div>
      <div v-else class="d-flex justify-content-center">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import moment from 'moment-timezone';

  import { getDatabase, ref, onValue } from "firebase/database"

  export default {
    computed: {
      ...mapGetters({
        user: "user"
      }),
      filterData: function  () {
        // Lifertermine die älter als (eingestellt) Tage sind entfernen
        var unixNow = moment().subtract(parseInt(this.settings.showAfterDelivery), 'days').unix()

        var filteredData = this.data.filter(i => i.deliveryDate > unixNow || i.deliveryDate === "")
        const newData = []

        filteredData.forEach(element => {
          element.shippingDate = this.betterDate(element.shippingDate, 0)
          element.deliveryDate = this.betterDate(element.deliveryDate)
          element.ordernumbers = {orNo: element.ordernumber, altOrNo: element.altOrdernumber}

          newData.push(element)
        });

        return newData
        },
    },
    data(){
      return{
        filter: null,
        filterOn: [],
        fields: [
            { sortable: true, label:'CL-Art. Nr.', key:'comlineSku'},
            { sortable: true, label:'Hersteller Art.Nr.', key:'manufacturerSku'},
            { sortable: true, label:'Bestellnummer', key:'ordernumbers'},
            { sortable: true, label:'Voraussichtliches Versanddatum', key:'shippingDate'},
            { sortable: true, label:'Bestellte Menge', key:'orderAmount'},
            { sortable: true, label:'Verschickte Menge', key:'shippedAmount'},
            { sortable: true, label:'Sendungsnummer', key:'trackingNo'},
            { sortable: true, label:'Voraussichtliches Ankunftsdatum', key:'deliveryDate'}
          ],
        data: undefined,
        settings: {}
      }
    },
    methods: {
      laodData(){
        const databaseRef = ref(getDatabase(), '/')
        onValue(databaseRef,  snapshot => {
          this.data = snapshot.child("tableData").val()
          this.settings = snapshot.child("settings").val()
          return null
        })
      },
      betterDate(date, add){
        if (add !== 0){
          add = this.settings.addToBuyDate
        }
        var output
        if (Number.isInteger(date)){
          output = moment.unix(date).add(add, 'days').format("DD.MM.YYYY")
        } else {
          output = date
        }
        return output
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
      }
    },
    beforeMount() {
      this.laodData()
    }
  };
</script>
<style>
  div, td, th {
    color: #373c42;
  }
  .stickySearch{
    position: sticky !important; 
    position: -webkit-sticky !important; 
    top: 60px;
    width: 100%; 
    background-color: white; 
  }
  .my-sticky-header thead th{
    position: sticky !important; 
    position: -webkit-sticky !important; 
    top: 129px !important;
    background-color: white;
  }
  td {
  padding: 0.3rem !important;
  vertical-align: middle !important;
  }
</style>
