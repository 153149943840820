<template>
  <div id="app">
    <div style="position: fixed; top: 0px; z-index: 9999998;">
    <Navbar />
    </div>
    <main style="margin-top: 60px;">
      <router-view></router-view>
    </main>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import { getAuth, onAuthStateChanged} from "firebase/auth";

export default {
  components: {
    Navbar
  },
  
  componentDidMount() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        console.log(user)
        this.$router.replace({
          name: "login"
        });
        return
      } else {
          console.log(user)
        this.$router.replace({
          name: "dashboard"
        });} 
    })
  }
};
</script>
<style scoped>
  /* table {
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 140px;
  z-index: 999999999999;
   display: inherit;
} */
</style>