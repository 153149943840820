import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from "./routes/index";

// Font Awesome
import './fontawesome.js'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";

// Firebase
import { firebase} from "./plugins/firebase";
import { getAuth, onAuthStateChanged} from "firebase/auth";

Vue.use(BootstrapVue);
Vue.config.productionTip = true
Vue.prototype.$firebase = firebase

onAuthStateChanged(getAuth(), (user) => {
  store.dispatch("fetchUser", user)
  let app = ''
  if (!app) {
    app = new Vue({
      render: h => h(App),
      router,
      store,
    }).$mount('#app')
  }
})

