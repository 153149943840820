import Vue from 'vue';
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'
import Settings from '../components/Settings'
import VueRouter from 'vue-router'
import NotAllowed from "../components/NotAllowedView"

// Firebase
import { auth } from "../plugins/firebase/index";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
    { 
        path: '*',
        redirect: '/login' 
    },
    { 
        path: '/', 
        redirect: '/login' 
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/token/:token',
        name: 'token',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notallowed',
        name: 'NotAllowed',
        component: NotAllowed,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            requiresAuth: true
        }
    }
]
});

router.beforeEach(async (to, from, next) => {

    const currentUser = await auth.currentUser
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const getIdTokenResult = currentUser ? await currentUser.getIdTokenResult(): false
    const isUser = getIdTokenResult ? getIdTokenResult.claims.user : false
    
    if ((to.name === 'login' || to.name === 'NotAllowed') && currentUser && isUser) next({ name: 'dashboard' })
    else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) next({ name: 'NotAllowed' })
    else if (requiresAuth && currentUser) next()
    else if (requiresAuth && !currentUser) next({ name: 'login' })
    else next()
})

export default router