<template>
  <div class="container">
    <div style="position: fixed !important; top: 2%; left: 0px; z-index: 9999999; width: 100%;" class="d-flex justify-content-center" >
      <b-alert 
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
      >Das Speichern war erfolgreich.
      </b-alert>
    </div>
    <div class="row justify-content-center">
       <div class="col-md-12  mb-4 mt-4">
        <div class="card">
          <div class="card-header">
            Datenimport
          </div>
          <div class="card-body">
            <div class="custom-file mb-3">
              <b-form-file type="file" ref="excelupload" class="excelupload" id="excelupload"  placeholder="Datei auswählen..." @change="(e)=>{excelFile = e.target.files[0]}" accept=".xlsx, .xls"></b-form-file>
            </div>
            <b-button variant="outline-primary" :disabled="!excelFile" @click="saveExcel">Speichern</b-button>
          </div>
        </div>
      </div>
      <div class="col-md-12  mb-4">
        <div class="card" >
          <div class="card-header">
            Generelle Einstellungen
          </div>
          <div class="card-body">
            <div>
              <label for="range-2">Zum Ankunftsdatum hinzufügen <b>{{ settings.addToBuyDate }}</b> Tage</label>
              <b-form-input id="range-2" @change="settingsButtonState = false" v-model="settings.addToBuyDate" type="range" min="0" max="14" step="1"></b-form-input>
            </div>
            <div>
              <label for="range-2">Tage nach der Ankunft anzeigen: <b>{{ settings.showAfterDelivery }}</b></label>
              <b-form-input id="range-2" @change="settingsButtonState = false" v-model="settings.showAfterDelivery" type="range" min="0" max="14" step="1"></b-form-input>
            </div>
            <b-button @click="setSettings();" variant="outline-primary" :disabled="settingsButtonState">Speichern</b-button>
          </div>
        </div>
      </div>
      <div class="col-md-12  mb-4">
        <div class="card" >
          <div class="card-header">
            Bestellnummer verknüpfung Hinzufügen
          </div>
          <div class="card-body">
            <b-form-input v-model="forecast" @change="linkingButtonState1 = false" placeholder="forecast Bestellnummer" class="mb-3"></b-form-input>
            <b-form-input v-model="newOrderNumber" @change="linkingButtonState2 = false" placeholder="neue Bestellnummer" class="mb-3"></b-form-input>
            <b-button variant="outline-primary" @click="setLinking();" :disabled="linkingButtonState1 && linkingButtonState2">Speichern</b-button>
          </div>
        </div>
      </div>
      <div class="col-md-12 mb-4">
        <div class="card" >
          <div class="card-header">
            Artikelnummer CSV Upload
          </div>
          <div class="card-body">
            <div class="custom-file mb-3">
               <b-form-file type="file" ref="csvupload" class="csvupload" id="csvupload" placeholder="Datei auswählen..." @change="(e)=>{csvFile = e.target.files[0]}" accept=".csv"></b-form-file>
            </div>
            <b-button variant="outline-primary" :disabled="csvFile === undefined" @click="loadCsv">Speichern</b-button>
            <div style="float:right;">
              <a :href="'./beispiel.csv'" target="_blank">Beispieldatei</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from 'moment-timezone';
import { getDatabase, ref, onValue, set} from "firebase/database"
import * as XLSX from 'xlsx'

export default {
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  data(){
    return {
      fileReader: new FileReader(),
      settings: {
        addToBuyDate: 0,
        showAfterDelivery: 0,
        ordernumbers:{},
        clNumbers:{}
      },
      data: undefined,
      forecast: '',
      newOrderNumber: '',
      settingsButtonState: true,
      linkingButtonState1: true,
      linkingButtonState2: true,
      dismissSecs: 3,
      dismissCountDown: 0,
      excelFile: undefined,
      csvFile: undefined
    }
  },
  methods: {
    setSettings(){
      set(ref(getDatabase(), `/settings`), this.settings)
      .then(()=>{
        this.showAlert()
      })
    },
    setLinking(){ 
      this.settings.ordernumbers[this.forecast] = this.newOrderNumber

      set(ref(getDatabase(), `/settings`), this.settings)
      .then(()=>{
          this.forecast = ""
          this.newOrderNumber = ""
        })
      this.linkingButtonState = true
      this.showAlert()
      this.updateData()
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    loadCsv() {
      var $this = this

      this.fileReader.onload = function(e) {
        var rows = e.target.result.split('\n')
        var obj = $this.settings.clNumbers
        rows.forEach(element => {
           var arr = element.split(';')
           obj[arr[0]] = arr[1].replace('\r', "")
           return null
        });
        set(ref(getDatabase(), `/settings/clNumbers`), obj)
        .then(()=>{
          $this.$refs['excelupload'].reset()
          $this.excelFile = undefined
          $this.showAlert()
        })
      }

      this.fileReader.readAsText(this.csvFile)
    },
    saveExcel() {
      var $this = this
      this.fileReader.onload = async function(e) {
        const rawData = XLSX.read(e.target.result, {type: 'binary'});
        var Data = {}

        rawData.SheetNames.forEach(function(sheetName) {
            let firstRowEmpty = false
            const json = XLSX.utils.sheet_to_json(rawData.Sheets[sheetName], {skipHeader:true})
            const keys = Object.keys(json[0])
            if(keys[0] == "__EMPTY") firstRowEmpty = true
            else firstRowEmpty = false
          

          if(firstRowEmpty){
            var range = XLSX.utils.decode_range(rawData.Sheets[sheetName]['!ref']);
            range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
            rawData.Sheets[sheetName]['!ref'] = XLSX.utils.encode_range(range);
          }

          Data[sheetName] = XLSX.utils.sheet_to_json(rawData.Sheets[sheetName], {skipHeader:true});
        })

        var updatedData = []
        Data['Backlog'].forEach(element => {
          if (element['Uncommitted Backlog Qty'] !== Number(0)) {
            updatedData.push({
              "comlineSku": "",
              "manufacturerSku": emptyStringIfUndefined(element['ST Model']),
              "ordernumber": emptyStringIfUndefined(element['Cust PO Num']),
              "shippingDate": "",
              "orderAmount": emptyStringIfUndefined(element['Uncommitted Backlog Qty']),
              "shippedAmount": "",
              "trackingNo": "",
              "deliveryDate": "",
              "altOrdernumber": "",
            })
          }
        })

        Data['Schedule Lines'].forEach(element => {
          let shippingDate = "";
          if (element['SchL Goods Issue Date'] != undefined){
            shippingDate = correctDate(element['SchL Goods Issue Date'])
          }

          updatedData.push({
            "comlineSku": "",
            "manufacturerSku": emptyStringIfUndefined(element['ST Model']),
            "ordernumber": emptyStringIfUndefined(element['Cust PO Num']),
            "shippingDate": shippingDate,
            "orderAmount": emptyStringIfUndefined(element['Schl. Confirmed Qty']),
            "shippedAmount": "",
            "trackingNo": "",
            "deliveryDate": "",
            "altOrdernumber": "",
          })
        })

        Data['Delivery'].forEach(element => {
          let deliveryDate = "";
          if (element['Dlv Act GI Date'] != undefined){
            deliveryDate = correctDate(element['Dlv Act GI Date'])
          }

          updatedData.push({
            "comlineSku": "",
            "manufacturerSku": emptyStringIfUndefined(element['ST Model']),
            "ordernumber": emptyStringIfUndefined(element['Cust PO Num']),
            "shippingDate": "",
            "orderAmount": "",
            "shippedAmount": emptyStringIfUndefined(element['Delivery Shipped Qty']),
            "trackingNo": emptyStringIfUndefined(element['House Airway Bill Num']),
            "deliveryDate": emptyStringIfUndefined(deliveryDate),
            "altOrdernumber": "",
          })
        })

        set(ref(getDatabase(), `/tableData`), updatedData)
        .then(()=>{
          $this.$refs['excelupload'].reset()
          $this.excelFile = undefined
          $this.showAlert()
        })
        $this.updateData()

        return null
      };
      function emptyStringIfUndefined(e) {
        var a
        if(e!= undefined){
          a = e
        }else{
          a = ""
        }
        return a
      }
      function correctDate(e) { 
        var a
        if (e != undefined && Number.isInteger(e)){
          a = (e - 25569) * 86400
        }else{
          a = ""
        }
        return a
      }
      this.fileReader.readAsBinaryString(this.excelFile)
    }, 
    laodData(){
      const databaseRef = ref(getDatabase(), '/')
      onValue(databaseRef,  snapshot => {
        this.data = snapshot.child("tableData").val()
        this.settings = snapshot.child("settings").val()
        return null
      })
    },
    updateData() {
      var updatedData = []
      this.data.forEach(element => {
        if(this.settings.clNumbers[element.manufacturerSku] !== undefined){
          element.comlineSku = this.settings.clNumbers[element.manufacturerSku]
        }

        if(this.settings.ordernumbers[element.ordernumber] !== undefined){
          element.altOrdernumber = this.settings.ordernumbers[element.ordernumber]
        }

        updatedData.push(element)
      })
        set(ref(getDatabase(), `/tableData`), updatedData)
        this.lastUpdate()
    },
    lastUpdate() {
      var lastUpdate = moment().tz('Europe/Berlin').format('DD.MM.YYYY hh:mm')
      var settings = this.settings
      settings.lastUpdate = lastUpdate
      set(ref(getDatabase(), `/settings`), settings)
    }
  },
  beforeMount() {
    // this.loadSettings()
    this.laodData()
  }
};
</script>
<style scoped>
.navbar{
  z-index: 9998 !important;
}
</style>
