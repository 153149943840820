import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {getDatabase, connectDatabaseEmulator} from "firebase/database";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBKZt8L3aXg5HqjsBT0viSmiute03YICCc",
  authDomain: "clgmbh-lacie-trademaster.firebaseapp.com",
  databaseURL: "https://clgmbh-lacie-trademaster.firebaseio.com",
  projectId: "clgmbh-lacie-trademaster",
  storageBucket: "clgmbh-lacie-trademaster.appspot.com",
  messagingSenderId: "573503388704",
  appId: "1:573503388704:web:bb93437cde79acecef9045",
  measurementId: "G-ZJTZ04FYXT"
};

const region = 'europe-west3';

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const db = getDatabase(firebase);
const functions = getFunctions(firebase, region);

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectDatabaseEmulator(db, "localhost", 9002);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export {firebase, auth} 