<template>
  <div id="anmelden" class="row h-100 p-5 justify-content-center align-items-center">
    <div class="col-md-8 col-lg-6 col-xl-4">
      <div class="card shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary">
        <div class="card-body p-5 text-center">
          <h1 class="h2 mb-5 font-weight-bold text-uppercase text-center d-flex align-items-center justify-content-center">
            <LogoComponent :fill="'#212529'" :height="'30px'" :width="'30px'" class="mr-3" />Shop Admin
          </h1>
          <div class="alert alert-danger form-group mb-5" v-if="fehlermeldung !== ''">
            {{fehlermeldung}}
          </div>
          <a class="btn btn-primary" href="./login">Retry</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from '../assets/Logo.vue'

export default {
  name: 'NotAllowedView',
  components: {
    LogoComponent
  },
  data() {
    return {
      fehlermeldung: 'You are not allowed to use this Tool. Please contact us. comlinedev@comline-shop.de'
    }
  },
}
</script>

<style scoped>
  .border-top {
    border-width: 5px !important;
  }
</style>
